import * as React from "react";

export default function LogoutPage() {
  const [cliente] = React.useState(localStorage.getItem("cliente"));
  /**
   *
   */
  const logout = () => {
    localStorage.removeItem("cliente");
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    /**
     *
     */
    if (cliente) {
      window.location.href = `https://${cliente}/sair/index/?redirect=/acesso-adm/`;
    } else {
      window.location.href = `https://gamadelivery.app/`;
    }
  };
  /**
   *
   */
  React.useEffect(() => {
    logout();
  });

  return <></>;
}
