import axios from "axios";
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const jar = new CookieJar();
const axiosInstance = wrapper(axios.create({
  withCredentials: true,
  jar,
}));

axiosInstance.interceptors.request.use(function (config) {
  config.headers["X-Api-Key"] = localStorage.getItem("token");
  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";

  return config;
});

export const getCliente = () => localStorage.getItem("cliente");
export const getVersao = () => "v1.0.5";

export const apiMotoboy = {
  validarToken: async () => {
    console.info("Consultando api para validar token;");
    return await axiosInstance.post(
      `https://${getCliente()}/api/motoboy/validarToken/`
    );
  },
  entregas: async () => {
    console.info("Consultando api de entregas;");
    return await axiosInstance.get(`https://${getCliente()}/api/motoboy/entregas/`);
  },
  resgatar: async (dados) => {
    console.info("Consultando api de resgate de pedido;");
    return await axiosInstance.post(
      `https://${getCliente()}/api/motoboy/resgatar/`,
      dados
    );
  },
  atualizarCoordenadas: async (dados) => {
    console.info("Consultando api de atualizar coordenadas;");
    return await axiosInstance.post(
      `https://${getCliente()}/api/motoboy/atualizarCoordenadas/`,
      dados
    );
  },
  alterarSituacaoEntrega: async (dados) => {
    console.info("Consultando api de alteração de situação da entrega;");
    return await axiosInstance.post(
      `https://${getCliente()}/api/motoboy/alterarSituacaoEntrega/`,
      dados
    );
  },
};
