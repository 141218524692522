import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";

import { useHistory, useLocation } from "react-router-dom";

import { apiMotoboy } from "../services/apiMotoboy";

const theme = createTheme();

export default function LoginPage(props) {
  let history = useHistory();
  /**
   *
   */
  const search = useLocation().search;
  const cliente = new URLSearchParams(search).get("cliente") || "";
  const token = new URLSearchParams(search).get("token") || "";
  const [error, setError] = React.useState("");
  /**
   *
   */
  const validarToken = React.useCallback(() => {
    if (cliente && token) {
      localStorage.setItem("cliente", cliente);
      localStorage.setItem("token", token);
    }

    if (!localStorage.getItem("cliente") || !localStorage.getItem("cliente")) {
      setError("Credenciais inválidas, volte e tente novamente.");
      return;
    }
    return apiMotoboy
      .validarToken()
      .then((res) => {
        // let data = JSON.encode(res.data?.data);
        // console.log(data);
        localStorage.setItem("usuario", JSON.stringify(res.data?.data));
        return history.push("/dashboard");
      })
      .catch((error) => {
        let { request } = error;
        let { responseText } = request;
        let response = JSON.parse(responseText);
        let { errors } = response;
        setError(errors);

        localStorage.removeItem("cliente");
        localStorage.removeItem("token");
        localStorage.removeItem("usuario");
      });
  }, [cliente, token, history]);
  /**
   *
   */
  React.useEffect(() => {
    validarToken();
  }, [validarToken]);

  return (
    <ThemeProvider theme={theme}>
      {error ? <Alert severity="error">{error}</Alert> : ""}
    </ThemeProvider>
  );
}
