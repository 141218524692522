import React from 'react';
import ReactDOM from 'react-dom';

import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
import DashboardPage from './pages/dashboard';

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route path="/dashboard">
          <DashboardPage />
        </Route>
        <Route path='/logout'>
          <LogoutPage />
        </Route>
        <Redirect from='*' to='/' />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
